import {useAppSelector} from 'hooks/redux';
import {FC} from 'react';
import {Navigate} from 'react-router-dom';

interface IRestrictedRoute {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  redirectTo?: string;
}
const RestrictedRoute: FC<IRestrictedRoute> = ({
  component: Component,
  redirectTo = '/',
}) => {
  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn);
  return isLoggedIn ? <Navigate to={redirectTo} replace /> : Component;
};

export default RestrictedRoute;
