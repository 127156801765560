import {FC, useState} from 'react';
import {
  EmailInput,
  FormContainer,
  JoinNowContainer,
  JoinNowHeading,
  SmallText,
  Text,
  TextContainer,
} from './JoinNow.styled';
import {DefaultSectionContainer} from 'shared-ui-elements/DefaultSectionContainer.styled';
import {DefaultButton} from 'shared-ui-elements/DefaultButton.styled';
import {useNavigate} from 'react-router-dom';

const JoinNow: FC = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const onJoinNow = () => navigate('/sign-up', {state: {signUp: {email}}});

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
  };
  return (
    <JoinNowContainer>
      <DefaultSectionContainer>
        <TextContainer>
          <JoinNowHeading>
            Join the Best
            <br /> Psychiatrist Network
          </JoinNowHeading>
          <Text>
            Show case your unique approach to mental health care. Expand your practice by
            connecting with patients who value your expertise and treatment philosophy.
          </Text>
          <FormContainer>
            <EmailInput
              type="email"
              placeholder="Email address"
              $width={'513px'}
              onChange={onEmailChange}
            />
            <DefaultButton $padding="12px 24px" onClick={onJoinNow}>
              Join now
            </DefaultButton>
          </FormContainer>
          <SmallText>By joining, you agree to our Terms and Conditions.</SmallText>
        </TextContainer>
      </DefaultSectionContainer>
    </JoinNowContainer>
  );
};

export {JoinNow};
