import styled from 'styled-components';
import heroBg from 'assets/home_hero_bg.jpg';
import heroMobileBg from 'assets/home_hero_bg_mob.jpg';
import {getColor, getFontSize, getSpacing} from 'theme';

const HeroContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  height: ${({theme}) => (theme.isDesktop ? '699px' : 'fit-content')};
  width: 100%;
  background-image: url(${({theme}) => (theme.isMobile ? heroMobileBg : heroBg)});
  background-size: cover;
  ${({theme}) =>
    theme.isDesktop
      ? {}
      : {
          flexWrap: 'wrap',
        }}

  padding-top: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '40px';
      case isTablet:
        return '40px';

      default:
        return '0px';
    }
  }};
  padding-bottom: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '16px';
      case isTablet:
        return '16px';

      default:
        return '0px';
    }
  }};
  padding-left: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '16px';
      case isTablet:
        return '24px';

      default:
        return '120px';
    }
  }};
  padding-right: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '16px';
      case isTablet:
        return '24px';

      default:
        return '120px';
    }
  }};
`;

const TextBox = styled.div`
  align-self: ${props => (props.theme.isDesktop ? 'center' : 'flex-start')};
  position: relative;
  z-index: 2;
  color: ${getColor('mainText')};
  max-width: ${props => (props.theme.isMobile ? '420px' : '640px')};
`;

const Header = styled.h1`
  font-weight: 800;
  margin-bottom: ${props => (props.theme.isMobile ? '8px' : '16px')};
  font-size: ${getFontSize('h1')};
  ${({theme}) =>
    theme.isTablet
      ? {
          fontSize: '48px',
        }
      : {}}
`;

const Text = styled.p`
  color: #666666;
  font-size: ${getFontSize('pMd')};
  margin-bottom: ${props => (props.theme.isMobile ? '24px' : '48px')};
`;

const BoldText = styled.p`
  font-weight: 700;
  font-size: ${getFontSize('h2Sm')};
  color: ${getColor('mainText')};
  margin-bottom: ${getSpacing('md')};
`;

const ButtonsBox = styled.div`
  display: flex;
  flex-direction: ${props => (props.theme.isDesktop ? 'row' : 'column')};
  align-items: flex-start;
  gap: ${props => (props.theme.isMobile ? '8px' : '16px')};
  margin-bottom: 24px;
`;

const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: fit-content;
  gap: 2px;
  align-self: center;
`;

const ImageCard = styled.img`
  object-fit: contain;
  object-position: center;

  ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return {width: '112px', height: '112px'};
      case isTablet:
        return {width: '160px', height: '160px'};

      default:
        return {width: '160px', height: '160px'};
    }
  }};
`;

export {
  HeroContainer,
  TextBox,
  Header,
  Text,
  BoldText,
  ButtonsBox,
  ImagesGrid,
  ImageCard,
};
