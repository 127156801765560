import {FC} from 'react';
import {Badge, FactCard, MythCard, Text, Wrapper} from './SliderCard.styled';
import {ReactComponent as FactBadge} from 'assets/factBadge.svg';
import {ReactComponent as MythBadge} from 'assets/mythBadge.svg';

interface IFactMythSliderCard {
  fact: {
    text: string;
  };
  myth: {
    text: string;
  };
}
const SliderCard: FC<IFactMythSliderCard> = ({fact, myth}) => {
  return (
    <Wrapper>
      <MythCard>
        <Badge>
          <MythBadge />
        </Badge>
        <Text>{myth.text}</Text>
      </MythCard>
      <FactCard>
        <Badge>
          <FactBadge />
        </Badge>
        <Text>{fact.text}</Text>
      </FactCard>
    </Wrapper>
  );
};

export {SliderCard};
