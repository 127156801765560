import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Pagination} from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './styles.css';

interface ISlider<T> {
  readonly slide?: React.ComponentType<T>;
  readonly data: Array<T & {bgComponent?: React.ComponentType<any>}>;
  readonly visibleSlides?: number;
  readonly spaceBetween?: number;
  readonly isVertical?: boolean;
}

const Slider = <T extends object>({
  data,
  slide: Slide,
  visibleSlides,
  spaceBetween,
  isVertical,
}: ISlider<T>) => {
  return (
    <Swiper
      direction={isVertical ? 'vertical' : 'horizontal'}
      modules={[Autoplay, Pagination]}
      spaceBetween={spaceBetween ?? 16}
      slidesPerView={visibleSlides ?? 'auto'}
      autoHeight={true}
      loop={true}
      pagination={true}
      speed={600}
      autoplay={{
        delay: 6000,
        disableOnInteraction: false,
      }}
      onInit={(swiper: any) => {
        const visibleSlidesCount = swiper.slidesPerViewDynamic();
        const slidesCount = swiper.slides.length;

        if (visibleSlidesCount === slidesCount) {
          swiper.autoplay.stop();
        }
      }}>
      {data.map((item, index) => {
        const BgComponent = item.bgComponent;

        return (
          <SwiperSlide style={{width: 'auto', flexShrink: 0}} key={index}>
            {Slide && <Slide {...(item as T)} />}
            {BgComponent && <BgComponent />}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export {Slider};
