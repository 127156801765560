import styled from 'styled-components';
import bgImage from 'assets/home_join_us_bg.jpg';
import {getColor, getSpacing} from 'theme';
import {Heading} from 'shared-ui-elements/Heading.styled';
import {DefaultInput} from 'shared-ui-elements/DefaultInput.styled';

const JoinNowContainer = styled.section`
  width: 100%;
  height: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '405px';
      case isTablet:
        return '550px';

      default:
        return '500px';
    }
  }};
  background-image: url(${bgImage});
  background-repeat: none;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  color: ${getColor('lightText')};
  max-width: 768px;
`;

const JoinNowHeading = styled(Heading)`
  color: ${getColor('lightText')};
  margin-bottom: ${getSpacing('lg')};
`;

const Text = styled.p`
  margin-bottom: ${getSpacing('xl')};
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.theme.isDesktop ? 'row' : 'column')};
  align-items: flex-start;
  gap: ${props => (props.theme.isMobile ? '8px' : '16px')};
`;

const EmailInput = styled(DefaultInput)`
  width: ${props => (props.theme.isMobile ? '100%' : '513px')};
`;

const SmallText = styled.p`
  font-size: 14px;
  margin-top: ${getSpacing('md')};
`;

export {
  JoinNowContainer,
  TextContainer,
  JoinNowHeading,
  FormContainer,
  Text,
  SmallText,
  EmailInput,
};
