import styled from 'styled-components';
import {getColor} from 'theme';
import {NavLink} from 'react-router-dom';

const Container = styled.div`
  display: flex;
  justify-content: ${({theme}) => (theme.isDesktop ? 'space-between' : 'center')};
  width: 100%;
  height: 100%;
  max-width: 1280px;
  padding: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '16px 16px';
      case isTablet:
        return '32px 24px';

      default:
        return '56px 40px';
    }
  }};
`;

const SideBar = styled.div`
  width: 260px;
`;

const NavMenu = styled.nav`
  width: inherit;
  margin-bottom: 28px;
`;

const NavLinkStyled = styled(NavLink)<{isLinkActive: boolean}>`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  color: ${({isLinkActive}) => (isLinkActive ? getColor('mainText') : 'gray')};
  position: relative;
  cursor: ${({isLinkActive}) => (isLinkActive ? 'pointer' : 'not-allowed')};

  & span {
    font-size: 12px;
    color: rgb(102, 102, 102);
  }

  &.active,
  &.active span {
    color: ${({isLinkActive}) => (isLinkActive ? getColor('secBg') : 'gray')};
    font-weight: ${({isLinkActive}) => (isLinkActive ? '600' : '400')};
  }

  &:not(:last-child) {
    border-bottom: 0.5px solid rgba(204, 204, 204, 1);
  }

  &:hover::after {
    content: ${({isLinkActive}) =>
      !isLinkActive ? '"Subscribe to see all benefits of this website"' : '""'};
    display: ${({isLinkActive}) => (!isLinkActive ? 'block' : 'none')};
    position: absolute;
    bottom: 100%;
    left: 0;
    background-color: #333;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
  }
`;

export {Container, SideBar, NavMenu, NavLinkStyled};
