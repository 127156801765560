export type PrivateLinkType = {
  title: string;
  subtitle?: string;
  link: string;
  isSubscribeOnly?: boolean;
};

export const privateMenuLinks: PrivateLinkType[] = [
  {
    title: 'Edit Profile',
    link: '/profile',
    isSubscribeOnly: false,
  },
  {
    title: 'Connect with Colleagues',
    link: '/profile/connect',
    isSubscribeOnly: true,
  },
  {
    title: 'Messaging',
    link: '/profile/messages',
    isSubscribeOnly: true,
  },
  {
    title: 'Forum',
    subtitle: '(Private - members can only post)',
    link: '/profile/forum',
    isSubscribeOnly: true,
  },
  {
    title: 'Psych Perspectives',
    subtitle: '(Public facing - members can only post)',
    link: '/profile/posts',
    isSubscribeOnly: true,
  },
  {title: 'Profile Settings', link: '/profile/settings', isSubscribeOnly: false},
  {title: 'My Subscription', link: '/profile/subscriptions', isSubscribeOnly: false},
];
