import {FC} from 'react';
import {Hero} from './components/Hero/Hero';
import {Benefits} from './components/Benefits/Benefits';
import {Stats} from './components/Stats/Stats';
import {Features} from './components/Features/Features';
import {Reviews} from './components/Reviews/Reviews';
import {Cities} from './components/Cities/Cities';
import {JoinNow} from './components/JoinNow/JoinNow';
import {useTheme} from 'styled-components';
import {FactsAndMyths} from './components/FactsAndMyths/FactsAndMyths';

const Home: FC = () => {
  const {isDesktop} = useTheme();
  return (
    <>
      <Hero />
      <Benefits />
      <Stats />
      <Features />
      <Reviews />
      <FactsAndMyths />
      <Cities />
      {isDesktop && <JoinNow />}
    </>
  );
};

export {Home};
