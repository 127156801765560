import {FC} from 'react';
import {Card, CardWrap, IconBackground, Text, Title} from './BenefitCard.styled';
import {useTheme} from 'styled-components';

interface ICard {
  readonly text: string;
  readonly title: string;
  readonly svgIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const BenefitCard: FC<ICard> = ({text, svgIcon: Icon, title}) => {
  const {isMobile} = useTheme();
  const iconSize = isMobile ? 38 : 50;
  return (
    <CardWrap>
      <Card>
        <div>
          <IconBackground>
            <Icon width={iconSize} height={iconSize} />
          </IconBackground>
        </div>
        <div>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </div>
      </Card>
    </CardWrap>
  );
};

export {BenefitCard};
