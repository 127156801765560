import {FC} from 'react';
import {
  Header,
  HeroContainer,
  TextBox,
  Text,
  BoldText,
  ButtonsBox,
  ImagesGrid,
  ImageCard,
} from './Hero.styled';
import {DefaultButton} from 'shared-ui-elements/DefaultButton.styled';
import {useNavigate} from 'react-router-dom';
import {adultClients, childClients} from 'constants/clientFocus';
import figure1 from 'assets/home_hero_figure_1.jpg';
import figure2 from 'assets/home_hero_figure_2.jpg';
import figure3 from 'assets/home_hero_figure_3.jpg';
import figure4 from 'assets/home_hero_figure_4.jpg';
import figure5 from 'assets/home_hero_figure_5.jpg';
import figure6 from 'assets/home_hero_figure_6.jpg';
import figure7 from 'assets/home_hero_figure_7.jpg';
import figure8 from 'assets/home_hero_figure_8.jpg';
import figure9 from 'assets/home_hero_figure_9.jpg';

const Hero: FC = () => {
  const navigate = useNavigate();
  const onAdultPsychiatristsClick = () => {
    navigate(`/search?page=1`, {state: {filterParams: {clientFocus: adultClients}}});
  };
  const onChildPsychiatristsClick = () => {
    navigate(`/search?page=1`, {
      state: {filterParams: {clientFocus: childClients}},
    });
  };
  return (
    <HeroContainer>
      <TextBox>
        <Header>
          Collaborative <br /> Care for a <span className="highlighted">Brighter</span>{' '}
          <br /> Tomorrow
        </Header>
        <Text>
          Only the best private practice M.D. and D.O. <br />
          psychiatrists for your care
        </Text>
        <BoldText>Help me find...</BoldText>
        <ButtonsBox>
          <DefaultButton $variant="transparent_black" onClick={onAdultPsychiatristsClick}>
            Adult Psychiatrists
          </DefaultButton>
          <DefaultButton $variant="transparent_black" onClick={onChildPsychiatristsClick}>
            Child/Adolescent Psychiatrists
          </DefaultButton>
        </ButtonsBox>
      </TextBox>
      <ImagesGrid>
        <ImageCard src={figure1} />
        <ImageCard src={figure2} />
        <ImageCard src={figure3} />
        <ImageCard src={figure4} />
        <ImageCard src={figure5} />
        <ImageCard src={figure6} />
        <ImageCard src={figure7} />
        <ImageCard src={figure8} />
        <ImageCard src={figure9} />
      </ImagesGrid>
    </HeroContainer>
  );
};

export {Hero};
