import {FC} from 'react';
import {
  BannerWrapper,
  CloseButton,
  ContentWrapper,
  Message,
} from './SubscribeNotification.styled';
import {ReactComponent as CloseIcon} from 'assets/close_icon.svg';
import {DefaultButton} from 'shared-ui-elements/DefaultButton.styled';
import {useNavigate} from 'react-router-dom';

interface ISubscribeNotification {
  readonly onClose?: () => unknown;
}

const SubscribeNotification: FC<ISubscribeNotification> = ({onClose = () => {}}) => {
  const navigate = useNavigate();
  const onSubscribeClick = () => navigate('/profile/subscriptions');

  return (
    <BannerWrapper>
      <ContentWrapper>
        <Message>
          Complete your profile for full access! Just hit "Submit changes" when you're
          ready.
        </Message>
        {/* <DefaultButton $variant="transparent_white" onClick={onSubscribeClick}>
          Subscribe
        </DefaultButton> */}
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </ContentWrapper>
    </BannerWrapper>
  );
};

export {SubscribeNotification};
