import styled from 'styled-components';
import {getColor} from 'theme';
import {ReactComponent as Logo} from 'assets/logo.svg';

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  background-color: rgba(0, 19, 60, 1);
`;

const FooterContainer = styled.div`
  flex-grow: 1;
  color: ${getColor('lightText')};
  margin-left: auto;
  margin-right: auto;
  min-width: ${({theme}) => (theme.isDesktop ? '975px' : '100%')};
  max-width: calc(100% - 128px);
`;

const FooterTopBox = styled.div`
  display: flex;
  flex-direction: ${props => (props.theme.isDesktop ? 'row' : 'column')};
  ${props => (props.theme.isDesktop ? {} : {gap: '24px'})}
  align-items: center;
  justify-content: space-between;
  padding-top: ${props => (props.theme.isDesktop ? '80px' : '32px')};
  padding-bottom: ${props => (props.theme.isDesktop ? '72px' : '40px')};
  border-bottom: 1px solid white;
`;

const FooterBottomBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${props => (props.theme.isDesktop ? 'row' : 'column')};
  padding-top: ${props => (props.theme.isDesktop ? '32px' : '16px')};
  padding-bottom: ${props => (props.theme.isDesktop ? '72px' : '48px')};
  gap: ${props => (props.theme.isDesktop ? '24px' : '16px')};
  color: ${getColor('secText')};
  font-size: 14px;
`;

const FooterMenuBox = styled.div`
  display: flex;
  flex-direction: ${props => (props.theme.isDesktop ? 'row' : 'column')};
  ${props => (props.theme.isDesktop ? {} : {gap: '8px'})}
  justify-content: space-between;
  align-items: center;
  gap: ${props => (props.theme.isDesktop ? '32px' : '8px')};
  font-weight: 700;
`;

const SocialsBox = styled.div`
  display: flex;
  gap: 12px;
`;

const StyledLink = styled.a`
  border-bottom: 1px solid ${getColor('secText')};
  font-weight: 600;
`;

const FooterLogo = styled(Logo)`
  color: ${getColor('lightText')};
  width: 292px;
  height: 43px;
`;

export {
  StyledFooter,
  FooterContainer,
  FooterMenuBox,
  SocialsBox,
  FooterTopBox,
  FooterBottomBox,
  StyledLink,
  FooterLogo,
};
