import styled from 'styled-components';
import {TextCircle} from './Benefits.styled';

const CardWrap = styled.div`
  width: ${props => (!props.theme.isDesktop ? '344px' : '512px')};
`;

const Card = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 28px;
  width: ${props => (!props.theme.isDesktop ? '303px' : '470px')};
  min-height: ${props => (!props.theme.isDesktop ? '100px' : '124px')};
  padding: 11px;
  border-radius: 100px 0 0 100px;
  background: linear-gradient(
    to right,
    rgba(245, 223, 197, 0.5) 0%,
    rgba(245, 223, 197, 0.15) 100%
  );
  box-shadow: 2.79px 1.86px 3.72px 0px rgba(0, 0, 0, 0.3);
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: ${props => (!props.theme.isDesktop ? '-41px' : '-62px')};
    width: 0;
    height: 0;
    border-top: ${props => (!props.theme.isDesktop ? '52px' : '62px')} solid transparent;
    border-bottom: ${props => (!props.theme.isDesktop ? '52px' : '62px')} solid
      transparent;
    border-left: ${props => (!props.theme.isDesktop ? '41px' : '62px')} solid
      rgba(245, 223, 197, 0.15);
  }
`;

const IconBackground = styled(TextCircle)`
  width: ${props => (!props.theme.isDesktop ? '67px' : '102px')};
  height: ${props => (!props.theme.isDesktop ? '67px' : '102px')};
`;

const Text = styled.p`
  font-size: ${props => (!props.theme.isDesktop ? '14px' : '18px')};
  max-width: 324px;
  font-weight: ${({theme}) => (theme.isDesktop ? 400 : 700)};
`;

const Title = styled(Text)`
  font-weight: 700;
`;

export {CardWrap, Card, IconBackground, Text, Title};
