import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {getColor, getSpacing} from 'theme';
import {DefaultButton} from 'shared-ui-elements/DefaultButton.styled';

const MobileMenuContainer = styled.div<{
  readonly $isHidden?: boolean;
}>`
  width: ${props => (props.$isHidden ? '0px' : '100%')};
  height: ${props => (props.$isHidden ? '0px' : '100%')};
  padding-top: 32px;
  padding-bottom: 64px;
  flex-grow: 1;
  display: ${props => (props.$isHidden ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${getColor('mainBg')};
  ${props => (props.$isHidden ? {opacity: 0, pointerEvents: 'none'} : {opacity: 1})}
`;

const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => (props.theme.isMobile ? '16px' : '28px')};
`;

const NavLinkStyled = styled(NavLink)<{isLinkActive?: boolean}>`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: ${props => (props.theme.isMobile ? '16px' : '24px')};
  position: relative;
  color: ${({isLinkActive = true}) => (isLinkActive ? getColor('mainText') : 'gray')};
  cursor: ${({isLinkActive = true}) => (isLinkActive ? 'pointer' : 'not-allowed')};
  text-align: center;

  & span {
    font-size: ${props => (props.theme.isMobile ? '14px' : '16px')};
    color: rgb(102, 102, 102);
  }

  &.active,
  &.active span {
    color: ${getColor('secBg')};
    font-weight: 600;
  }

  &:hover::after {
    content: ${({isLinkActive = true}) =>
      !isLinkActive ? '"Subscribe to see all benefits of this website"' : '""'};
    display: ${({isLinkActive = true}) => (!isLinkActive ? 'block' : 'none')};
    position: absolute;
    bottom: 100%;
    left: 0;
    background-color: #333;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
  }
`;

const ButtonsBox = styled.div`
  display: flex;
  gap: ${getSpacing('sm')};
`;

const AuthBtn = styled(DefaultButton)`
  width: 167px;
  padding: 12px 0px;
  display: flex;
  justify-content: center;
`;

const PrivateMenuTitle = styled.p`
  text-align: center;
  font-size: ${props => (props.theme.isMobile ? '14px' : '20px')};
  color: ${getColor('secBg')};
  margin-top: 56px;
  margin-bottom: 16px;
`;

export {
  MobileMenuContainer,
  MenuList,
  ButtonsBox,
  NavLinkStyled,
  AuthBtn,
  PrivateMenuTitle,
};
