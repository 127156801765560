import {FC} from 'react';
import {
  BoldText,
  ItalicText,
  MediumText,
  StatsContainer,
  StatsItem1,
  StatsItem2,
  StatsItem3,
  PaddingBox,
} from './Stats.styled';

const Stats: FC = () => {
  return (
    <StatsContainer>
      <StatsItem1>
        <PaddingBox>
          <BoldText as="span">3</BoldText>
          <p>
            easy steps <br /> to book a session
          </p>
        </PaddingBox>
      </StatsItem1>
      <StatsItem2>
        <PaddingBox>
          {' '}
          <BoldText as="span">5 min</BoldText>
          <p>
            average <br /> search time
          </p>
        </PaddingBox>
      </StatsItem2>
      <StatsItem3>
        <PaddingBox>
          <MediumText>
            Thousands have found their{' '}
            <ItalicText as="span">perfect psychiatrist</ItalicText> with us.
          </MediumText>
        </PaddingBox>
      </StatsItem3>
    </StatsContainer>
  );
};

export {Stats};
