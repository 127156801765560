import {ISignIn} from 'pages/signIn/SignIn';
import api from 'api/axios';
import {useAppDispatch, useAppSelector} from './redux';
import {
  AuthState,
  logIn as logInRedux,
  logOut as logOutRedux,
} from 'store/reducers/auth/authSlice';
import {ISignUp} from 'pages/signUp/SignUp';
import {useCallback} from 'react';

const useAuthApi = () => {
  const dispatch = useAppDispatch();
  const {token, isLoggedIn} = useAppSelector(state => state.auth) as AuthState;

  const logIn = useCallback(
    async (data: ISignIn) => {
      try {
        const res = await api.post('/auth/login', data);
        const {token, user} = res.data;
        dispatch(logInRedux({user, token}));
        api.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`;
        return user;
      } catch (error) {
        console.log('Error in logIn, useAuth.ts: ', error);
        return null;
      }
    },
    [dispatch],
  );

  const signUp = useCallback(async (data: ISignUp) => {
    try {
      const res = await api.post('/auth/register', data);
      return res?.data?.user;
    } catch (error) {
      console.log('Error in signUp, useAuth.ts: ', error);
      return null;
    }
  }, []);

  interface IChangePassword {
    oldPassword: string;
    newPassword: string;
  }
  const changePassword = useCallback(async (data: IChangePassword) => {
    try {
      const res = await api.patch('/auth/change-password', data);
      return res?.data;
    } catch (error) {
      console.log('Error in changePassword, useAuth.ts: ', error);
      return null;
    }
  }, []);

  const requestResetPassword = useCallback(async (data: {email: string}) => {
    try {
      const res = await api.post('/auth/forgot-password', data);
      return res?.data;
    } catch (error) {
      console.log('Error in requestResetPassword, useAuth.ts: ', error);
      return null;
    }
  }, []);

  interface IResetPassword {
    token: string;
    newPassword: string;
  }
  const resetPassword = useCallback(async (data: IResetPassword) => {
    try {
      const res = await api.post('/auth/reset-password', data);
      return res?.data;
    } catch (error) {
      console.log('Error in resetPassword, useAuth.ts: ', error);
      return null;
    }
  }, []);

  const confirmEmail = useCallback(
    async (confirmEmailToken: string) => {
      try {
        const res = await api.get('/auth/verify-email', {
          params: {token: confirmEmailToken},
        });
        const {token, user} = res.data;
        dispatch(logInRedux({user, token}));
        api.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`;
        return user;
      } catch (error) {
        console.log('Error in confirmEmail, useAuth.ts: ', error);
        return null;
      }
    },
    [dispatch],
  );

  const setupRefreshInterceptor = useCallback(async () => {
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`;
    }
    api.interceptors.response.use(
      response => response,
      async error => {
        const {refresh_token: old_refresh_token} = token ?? {};
        const originalRequest = error.config;
        if (
          error.response.status === 401 &&
          isLoggedIn &&
          old_refresh_token &&
          !originalRequest._retry
        ) {
          try {
            originalRequest._retry = true;
            const response = await api.post('/auth/refresh-token', {
              refresh_token: old_refresh_token,
            });
            const {token, user} = response.data;

            dispatch(logInRedux({user, token}));

            api.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${token?.access_token}`;
            originalRequest.headers['Authorization'] = `Bearer ${token?.access_token}`;

            return api(originalRequest);
          } catch (refreshError) {
            console.log('Error while refresh token: ', refreshError);
            dispatch(logOutRedux());
            api.defaults.headers.common['Authorization'] = null;
          }
        }
      },
    );
  }, [token, isLoggedIn, dispatch]);

  const logOut = useCallback(() => {
    dispatch(logOutRedux());
    api.defaults.headers.common['Authorization'] = null;
  }, [dispatch]);

  return {
    logIn,
    signUp,
    setupRefreshInterceptor,
    logOut,
    confirmEmail,
    changePassword,
    requestResetPassword,
    resetPassword,
  };
};

export {useAuthApi};
