import styled from 'styled-components';
import {getColor, getFontSize} from 'theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 642px;
`;

const MythCard = styled.div`
  position: relative;
  border-radius: 0px 40px 0px 40px;
  border: 1px solid #f11100;
  padding: 60px 40px;
  background-color: rgba(180, 35, 24, 0.2);
`;

const FactCard = styled.div`
  position: relative;
  border-radius: 0px 40px 0px 40px;
  border: 1px solid #01bf24;
  padding: 60px 40px;
  background-color: rgba(2, 122, 72, 0.2);
`;

const Text = styled.p`
  font-size: ${getFontSize('pSm')};
  font-weight: 700;
  color: ${getColor('lightText')};
`;

const Badge = styled.div`
  position: absolute;
  top: -35px;
  left: -40px;
  /* transform: rotate(-45deg); */
`;

export {Wrapper, MythCard, FactCard, Text, Badge};
