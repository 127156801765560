import {NavLink} from 'react-router-dom';
import {ReactComponent as Logo} from 'assets/logo.svg';
import styled from 'styled-components';
import {getColor, getSpacing} from 'theme';

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
`;

const HeaderContainer = styled.div`
  flex-grow: 1;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding-top: ${({theme}) => (theme.isMobile ? '17px' : '31px')};
  padding-bottom: ${({theme}) => (theme.isMobile ? '17px' : '31px')};
  ${props => (props.theme.isDesktop ? {minWidth: '1110px'} : {})}
  max-width: calc(100% - ${props => (props.theme.isDesktop ? '128px' : '32px')});
`;

const HeaderMenuBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${getSpacing('xl')};
`;

const AuthButtonsBox = styled.div`
  display: flex;
  gap: ${getSpacing('md')};
`;

const NavLinkStyled = styled(NavLink)`
  display: flex;
  align-items: center;
  color: ${getColor('mainText')};

  &.active {
    color: ${getColor('secBg')};
    font-weight: 600;
  }
`;

const StyledLogo = styled(Logo)`
  color: ${getColor('secBg')};
  width: 292px;
  height: 43px;
`;

const UserBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
`;

const UserName = styled.p`
  font-size: 16px;
  font-weight: 600;
  text-align: right;
`;

const UserPosition = styled.p`
  font-size: 14px;
  text-align: right;
`;

const MobileMenuContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MobileMenuBtn = styled.button<{$showPoint?: boolean}>`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    display: ${({$showPoint}) => ($showPoint ? 'block' : 'none')};
    left: 0px;
    top: 0px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: orange;
  }
`;

export {
  StyledHeader,
  HeaderContainer,
  HeaderMenuBox,
  AuthButtonsBox,
  NavLinkStyled,
  StyledLogo,
  UserBox,
  UserName,
  UserPosition,
  MobileMenuContainer,
  MobileMenuBtn,
};
