import {FC, MouseEvent, useEffect, useState} from 'react';
import {useTheme} from 'styled-components';
import {Container, NavLinkStyled, NavMenu, SideBar} from './LoggedInLayout.styled';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {PrivateLinkType, privateMenuLinks} from '../../constants/privateMenuLinks';
import {adminLinks} from 'constants/adminLinks';
import {LogOutBtn} from 'shared-ui-elements/LogOutBtn/LogOutBtn';
import {useAppSelector} from 'hooks/redux';
import {AuthState} from 'store/reducers/auth/authSlice';
import {SubscribeNotification} from './components/SubscribeNotification/SubscribeNotification';
import {ReactComponent as BellIcon} from 'assets/bell_icon.svg';
import {useUserApi} from 'hooks/useUserApi';
import {PendingChangesNotification} from './components/PendingChangesNotification/PendingChangesNotification';

const LoggedInLayout: FC = () => {
  const {isDesktop} = useTheme();
  const {user} = useAppSelector(state => state.auth) as AuthState;

  const isAccessAllowed = user?.doctorInfo.isVerified;
  const isPendingChanges = user?.isProfileChangesOnReview;

  const [isShowSubsBanner, setIsShowSubsBanner] = useState(false);
  const [isShowPendingEditsBanner, setIsShowPendingEditsBanner] = useState(false);
  const links: PrivateLinkType[] = [
    ...privateMenuLinks,
    ...(user?.role.includes('admin') ? adminLinks : []),
  ];
  const {getUserPrivate} = useUserApi();
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavLinkClick = (e: MouseEvent<HTMLAnchorElement>, isActive: boolean) => {
    if (!isActive) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setIsShowSubsBanner(!isAccessAllowed);
  }, [isAccessAllowed]);

  useEffect(() => {
    setIsShowPendingEditsBanner(Boolean(isPendingChanges));
  }, [isPendingChanges]);

  useEffect(() => {
    const checkAccess = () => {
      const currentLink = links.find(({link}) => link === location.pathname);
      const isLinkAllowed = !currentLink?.isSubscribeOnly || isAccessAllowed;
      if (!isLinkAllowed) {
        navigate('/profile');
      }
    };
    checkAccess();
    getUserPrivate();
  }, [location]);

  return (
    <>
      {isShowSubsBanner && (
        <SubscribeNotification onClose={() => setIsShowSubsBanner(false)} />
      )}
      {isShowPendingEditsBanner && (
        <PendingChangesNotification onClose={() => setIsShowPendingEditsBanner(false)} />
      )}
      <Container>
        {isDesktop && (
          <SideBar>
            <NavMenu>
              {links.map(({title, link, subtitle, isSubscribeOnly}) => {
                const isLinkActive = Boolean(!isSubscribeOnly || isAccessAllowed);
                const isShowBell =
                  link === '/profile/messages' && user?.hasUnreadMessages;
                return (
                  <NavLinkStyled
                    key={title}
                    to={link}
                    end={link === '/profile'}
                    isLinkActive={isLinkActive}
                    onClick={e => handleNavLinkClick(e, isLinkActive)}>
                    <p>
                      {title}
                      {subtitle && (
                        <>
                          {' '}
                          <span>{subtitle}</span>
                        </>
                      )}
                    </p>
                    {isShowBell && <BellIcon width={24} height={24} />}
                  </NavLinkStyled>
                );
              })}
            </NavMenu>
            <LogOutBtn />
          </SideBar>
        )}
        <Outlet />
      </Container>
    </>
  );
};

export {LoggedInLayout};
