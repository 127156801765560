import api from 'api/axios';
import {useCallback} from 'react';
import {useAppDispatch} from './redux';
import {updUser} from 'store/reducers/auth/authSlice';
import {IDoctor} from 'types/iDoctor';

const useUserApi = () => {
  const dispatch = useAppDispatch();

  const getUserPrivate = useCallback(async () => {
    try {
      const res = await api.get('/users/profile');
      const user: IDoctor = res.data;
      if (user) {
        dispatch(updUser(user));
        return user;
      }
    } catch (error) {
      console.log('Error in getUserPrivate, useUserApi.ts', error);
      return null;
    }
  }, []);

  const getUserPublic = useCallback(async (id: string) => {
    try {
      const res = await api.get(`/users/${id}`);
      return res.data as IDoctor;
    } catch (error) {
      console.log('Error in getUserPublic, useUserApi.ts', error);
      return null;
    }
  }, []);

  const verifyUser = useCallback(
    async (data: {isVerified: boolean; text?: string}, userId: string) => {
      try {
        const res = await api.patch(`/users/verify-user/${userId}`, data);
        return res.data as IDoctor;
      } catch (error) {
        console.log('Error in verifyUser, useUserApi.ts', error);
        return null;
      }
    },
    [],
  );

  const verifyUserChanges = useCallback(async (userId: string) => {
    try {
      const res = await api.put(`/users/verify-user-changes/${userId}`);
      return res.data as IDoctor;
    } catch (error) {
      console.log('Error in verifyUserChanges, useUserApi.ts', error);
      return null;
    }
  }, []);

  const getUsers = useCallback(
    async (limit?: number, page?: number, filter?: {[field: string]: any}) => {
      try {
        const params: any = {
          limit: limit ?? 10,
          page: page ?? 1,
        };
        if (filter) {
          params.filter = filter;
        }
        const res = await api.get('/users', {
          params,
        });
        return res.data as {data: IDoctor[]; total: number};
      } catch (error) {
        console.log('Error in getUsers, useUserApi.ts: ', error);
      }
    },
    [],
  );

  const getUsersForAdmin = useCallback(
    async (limit?: number, page?: number, filter?: {[field: string]: any}) => {
      try {
        const params: any = {
          limit: limit ?? 10,
          page: page ?? 1,
        };
        if (filter) {
          params.filter = filter;
        }
        const res = await api.get('/users/all', {
          params,
        });
        return res.data as {data: IDoctor[]; total: number};
      } catch (error) {
        console.log('Error in getUsersForAdmin, useUserApi.ts: ', error);
      }
    },
    [],
  );

  const editUser = async (updObj: {[field: string]: any}) => {
    try {
      const res = await api.patch('/users/edit', updObj, {});
      const user: IDoctor = res.data;
      if (user) {
        dispatch(updUser(user));
        return user;
      }
    } catch (error) {
      console.error('Error in editUser, useUserApi.ts:', error);
    }
  };

  const submitEditUser = async () => {
    try {
      const res = await api.patch('/users/edit/submit');
      const user: IDoctor = res.data;
      if (user) {
        dispatch(updUser(user));
        return user;
      }
    } catch (error) {
      console.error('Error in submitEditUser, useUserApi.ts:', error);
    }
  };

  const deleteBlockedUsers = async () => {
    try {
      await api.delete('/users/clear-blocked');
      return {isSuccess: true};
    } catch (error) {
      console.error('Error in deleteBlockedUsers, useUserApi.ts:', error);
      return {isSuccess: false};
    }
  };

  return {
    getUserPrivate,
    verifyUser,
    getUsers,
    getUsersForAdmin,
    getUserPublic,
    editUser,
    deleteBlockedUsers,
    verifyUserChanges,
    submitEditUser,
  };
};

export {useUserApi};
