import styled from 'styled-components';
import {getColor} from 'theme';

const BannerWrapper = styled.div`
  width: 100%;
  background-color: ${getColor('secBg')};
  color: ${getColor('lightText')};
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Message = styled.span`
  flex: 1;
  margin-right: 16px;
`;

const CloseButton = styled.button`
  background-color: transparent;
  color: ${getColor('lightText')};
  border: none;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    color: #f5c6cb;
  }
`;

export {BannerWrapper, ContentWrapper, Message, CloseButton};
