import axios from 'axios';
import toast from 'react-hot-toast';

const baseURL = process.env.REACT_APP_BACKEND_URL;
const api = axios.create({baseURL});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      const statusCode = error.response.status;
      if (statusCode >= 400 && statusCode < 500) {
        const message = error.response.data.message || 'An error occurred';
        //Ignore error msg on access token expiration
        if (message === 'Unauthorized') return Promise.reject(error);
        toast.error(message);
      } else if (statusCode >= 500) {
        toast.error('Oops, something went wrong');
      }
    } else {
      toast.error('Network error, please try again');
    }
    return Promise.reject(error);
  },
);

export default api;
