import {FC} from 'react';
import {Slider} from 'modules/Slider/Slider';
import {
  Card1,
  Card2,
  Card3,
  Card4,
  CardsContainer,
  NoBrakeText,
  ReviewsContainer,
  ReviewsHeading,
} from './Reviews.styled';
import avatar from 'assets/comment_avatar.jpg';

const reviewsData = [
  {
    bgComponent: () => (
      <Card1>
        <p>SO EASY TO USE! FOUND A GREAT PSYCHIATRIST IN NO TIME.</p>
      </Card1>
    ),
  },
  {
    bgComponent: () => (
      <Card2>
        <p>LOVE THE SIMPLICITY AND EFFECTIVENESS OF THIS DIRECTORY!</p>
      </Card2>
    ),
  },
  {
    bgComponent: () => (
      <Card3>
        <p>GAME-CHANGER IN MY PSYCHIATRIST SEARCH. THANK YOU!</p>
      </Card3>
    ),
  },
  {
    bgComponent: () => (
      <Card4>
        <p>HIGHLY RECOMMEND THIS DIRECTORY FOR ANYONE SEEKING MENTAL HEALTH SUPPORT.</p>
      </Card4>
    ),
  },
];

const Reviews: FC = () => {
  return (
    <ReviewsContainer>
      <ReviewsHeading>
        What our patients <NoBrakeText>say ...</NoBrakeText>
      </ReviewsHeading>
      <div>
        <CardsContainer>
          <Slider data={reviewsData} />
        </CardsContainer>
      </div>
    </ReviewsContainer>
  );
};

export {Reviews};
