import {FC} from 'react';
import {BtnWrap} from './LogOutBtn.styled';
import {ReactComponent as LogoutIcon} from 'assets/logoutIcon.svg';
import {useAuthApi} from 'hooks/useAuth';

const LogOutBtn: FC = () => {
  const {logOut} = useAuthApi();

  return (
    <BtnWrap onClick={logOut}>
      <LogoutIcon />
      <p>Log Out</p>
    </BtnWrap>
  );
};

export {LogOutBtn};
