import {FC} from 'react';
import {
  BannerWrapper,
  CloseButton,
  ContentWrapper,
  Message,
} from './PendingChangesNotification.styled';
import {ReactComponent as CloseIcon} from 'assets/close_icon.svg';

interface INotification {
  readonly onClose?: () => unknown;
}

const PendingChangesNotification: FC<INotification> = ({onClose = () => {}}) => {
  return (
    <BannerWrapper>
      <ContentWrapper>
        <Message>
          Your profile changes are under review and will be applied after verification.
          Thank you !
        </Message>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </ContentWrapper>
    </BannerWrapper>
  );
};

export {PendingChangesNotification};
