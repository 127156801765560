export enum US_STATES_ENUM {
  AL = 'Alabama',
  AK = 'Alaska',
  AZ = 'Arizona',
  AR = 'Arkansas',
  CA = 'California',
  CO = 'Colorado',
  CT = 'Connecticut',
  DE = 'Delaware',
  FL = 'Florida',
  GA = 'Georgia',
  HI = 'Hawaii',
  ID = 'Idaho',
  IL = 'Illinois',
  IN = 'Indiana',
  IA = 'Iowa',
  KS = 'Kansas',
  KY = 'Kentucky',
  LA = 'Louisiana',
  ME = 'Maine',
  MD = 'Maryland',
  MA = 'Massachusetts',
  MI = 'Michigan',
  MN = 'Minnesota',
  MS = 'Mississippi',
  MO = 'Missouri',
  MT = 'Montana',
  NE = 'Nebraska',
  NV = 'Nevada',
  NH = 'New Hampshire',
  NJ = 'New Jersey',
  NM = 'New Mexico',
  NY = 'New York',
  NC = 'North Carolina',
  ND = 'North Dakota',
  OH = 'Ohio',
  OK = 'Oklahoma',
  OR = 'Oregon',
  PA = 'Pennsylvania',
  PR = 'Puerto Rico',
  RI = 'Rhode Island',
  SC = 'South Carolina',
  SD = 'South Dakota',
  TN = 'Tennessee',
  TX = 'Texas',
  UT = 'Utah',
  VT = 'Vermont',
  VA = 'Virginia',
  WA = 'Washington',
  WV = 'West Virginia',
  WI = 'Wisconsin',
  WY = 'Wyoming',
}

export const statesArray = [
  {state: 'Alabama', value: US_STATES_ENUM.AL},
  {state: 'Alaska', value: US_STATES_ENUM.AK},
  {state: 'Arizona', value: US_STATES_ENUM.AZ},
  {state: 'Arkansas', value: US_STATES_ENUM.AR},
  {state: 'California', value: US_STATES_ENUM.CA},
  {state: 'Colorado', value: US_STATES_ENUM.CO},
  {state: 'Connecticut', value: US_STATES_ENUM.CT},
  {state: 'Delaware', value: US_STATES_ENUM.DE},
  {state: 'Florida', value: US_STATES_ENUM.FL},
  {state: 'Georgia', value: US_STATES_ENUM.GA},
  {state: 'Hawaii', value: US_STATES_ENUM.HI},
  {state: 'Idaho', value: US_STATES_ENUM.ID},
  {state: 'Illinois', value: US_STATES_ENUM.IL},
  {state: 'Indiana', value: US_STATES_ENUM.IN},
  {state: 'Iowa', value: US_STATES_ENUM.IA},
  {state: 'Kansas', value: US_STATES_ENUM.KS},
  {state: 'Kentucky', value: US_STATES_ENUM.KY},
  {state: 'Louisiana', value: US_STATES_ENUM.LA},
  {state: 'Maine', value: US_STATES_ENUM.ME},
  {state: 'Maryland', value: US_STATES_ENUM.MD},
  {state: 'Massachusetts', value: US_STATES_ENUM.MA},
  {state: 'Michigan', value: US_STATES_ENUM.MI},
  {state: 'Minnesota', value: US_STATES_ENUM.MN},
  {state: 'Mississippi', value: US_STATES_ENUM.MS},
  {state: 'Missouri', value: US_STATES_ENUM.MO},
  {state: 'Montana', value: US_STATES_ENUM.MT},
  {state: 'Nebraska', value: US_STATES_ENUM.NE},
  {state: 'Nevada', value: US_STATES_ENUM.NV},
  {state: 'New Hampshire', value: US_STATES_ENUM.NH},
  {state: 'New Jersey', value: US_STATES_ENUM.NJ},
  {state: 'New Mexico', value: US_STATES_ENUM.NM},
  {state: 'New York', value: US_STATES_ENUM.NY},
  {state: 'North Carolina', value: US_STATES_ENUM.NC},
  {state: 'North Dakota', value: US_STATES_ENUM.ND},
  {state: 'Ohio', value: US_STATES_ENUM.OH},
  {state: 'Oklahoma', value: US_STATES_ENUM.OK},
  {state: 'Oregon', value: US_STATES_ENUM.OR},
  {state: 'Pennsylvania', value: US_STATES_ENUM.PA},
  {state: 'Puerto Rico', value: US_STATES_ENUM.PR},
  {state: 'Rhode Island', value: US_STATES_ENUM.RI},
  {state: 'South Carolina', value: US_STATES_ENUM.SC},
  {state: 'South Dakota', value: US_STATES_ENUM.SD},
  {state: 'Tennessee', value: US_STATES_ENUM.TN},
  {state: 'Texas', value: US_STATES_ENUM.TX},
  {state: 'Utah', value: US_STATES_ENUM.UT},
  {state: 'Vermont', value: US_STATES_ENUM.VT},
  {state: 'Virginia', value: US_STATES_ENUM.VA},
  {state: 'Washington', value: US_STATES_ENUM.WA},
  {state: 'West Virginia', value: US_STATES_ENUM.WV},
  {state: 'Wisconsin', value: US_STATES_ENUM.WI},
  {state: 'Wyoming', value: US_STATES_ENUM.WY},
];
