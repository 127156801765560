import {FC, useState} from 'react';
import {DefaultSectionContainer} from 'shared-ui-elements/DefaultSectionContainer.styled';
import {
  CitiesHeader,
  CitiesList,
  CityButton,
  CityItem,
  ExpandBtn,
  FlexCenterBox,
  GradientOverlay,
  Point,
} from './Cities.styled';
import {useNavigate} from 'react-router-dom';
import {statesArray} from 'constants/usStatesEnum';

const Cities: FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const onStateClick = (state: string) => {
    navigate(`/search?page=1`, {
      state: {filterParams: {address: state}},
    });
  };
  return (
    <DefaultSectionContainer>
      <CitiesHeader>
        Find <span className="highlighted">detailed listings</span> for Best Psychiatrists
        in <span className="highlighted">United States</span>
      </CitiesHeader>
      <CitiesList $isExpanded={isExpanded}>
        {statesArray.map(({state}) => (
          <CityButton $variant="transparent_black" key={state}>
            <CityItem onClick={() => onStateClick(state)}>
              <Point />
              <p>{state}</p>
            </CityItem>
          </CityButton>
        ))}
        {!isExpanded && <GradientOverlay />}
      </CitiesList>
      {!isExpanded && (
        <FlexCenterBox>
          <ExpandBtn onClick={() => setIsExpanded(true)}>View all</ExpandBtn>
        </FlexCenterBox>
      )}
    </DefaultSectionContainer>
  );
};

export {Cities};
