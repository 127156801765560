import styled from 'styled-components';
import {getColor, getFontSize, getSpacing} from 'theme';
import {Heading} from 'shared-ui-elements/Heading.styled';
import bgImg from 'assets/facts_and_myths_bg.jpg';
import bgImgMod from 'assets/facts_and_myths_bg_mob.jpg';

const Container = styled.section`
  background-image: url(${({theme}) => (theme.isMobile ? bgImgMod : bgImg)});
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* justify-content: stretch; */
  align-items: stretch;
  width: 100%;
  padding-top: ${({theme}) => (theme.isDesktop ? '80px' : '40px')};
  padding-bottom: ${({theme}) => (theme.isDesktop ? '80px' : '40px')};
`;

const TextContainer = styled.div`
  width: ${props => (props.theme.isMobile ? '100%' : '384px')};
  min-width: 320px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextBox = styled.div`
  max-width: ${props => (props.theme.isMobile ? '100%' : '384px')};
  /* max-width: 358px; */
  padding-left: ${getSpacing('md')};
  padding-right: ${getSpacing('md')};

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

const FeaturesHeading = styled(Heading)`
  font-size: ${getFontSize('h2Md')};
  margin-bottom: 24px;
  color: ${getColor('lightText')};
`;

const SliderContainer = styled.div`
  flex: 1;
  width: ${({theme}) => (theme.isMobile ? '100%' : '707px')};
  min-width: ${({theme}) => (theme.isMobile ? '100%' : '500px')};
  /* max-width: 707px; */
  max-height: ${({theme}) => (theme.isMobile ? '650px' : '500px')};
  align-self: center;
  overflow: hidden;
  padding: 40px 80px 30px 35px;
`;

const Text = styled.p`
  color: ${getColor('lightText')};
  font-size: ${getFontSize('pLg')};
  margin-bottom: 16px;
`;

export {Container, TextContainer, TextBox, Text, SliderContainer, FeaturesHeading};
