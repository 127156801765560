import styled from 'styled-components';
import {Heading} from 'shared-ui-elements/Heading.styled';
import {DefaultSectionContainer} from 'shared-ui-elements/DefaultSectionContainer.styled';
import {getColor, getFontSize} from 'theme';

import bg1 from 'assets/comment_slide_bg_1.svg';
import bg2 from 'assets/comment_slide_bg_2.svg';
import bg3 from 'assets/comment_slide_bg_3.svg';
import bg4 from 'assets/comment_slide_bg_4.svg';

const ReviewsContainer = styled(DefaultSectionContainer)`
  overflow: hidden;
`;

const ReviewsHeading = styled(Heading)`
  margin-bottom: ${props => (props.theme.isMobile ? '24px' : '60px')};
`;

const CardsContainer = styled.ul``;

const BaseCard = styled.div`
  font-size: ${getFontSize('pSm')};
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: bottom;
  background-size: cover;
`;

const Card1 = styled(BaseCard)`
  background-image: url(${bg1});
  height: 250px;
  width: 270px;
  padding-left: 70px;
  padding-right: 100px;
`;

const Card2 = styled(BaseCard)`
  background-image: url(${bg2});
  height: 236px;
  width: 236px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
  color: ${getColor('lightText')};
`;

const Card3 = styled(BaseCard)`
  background-image: url(${bg3});
  height: 245px;
  width: 292px;
  padding-left: 60px;
  padding-right: 50px;
  text-align: center;
`;

const Card4 = styled(BaseCard)`
  background-image: url(${bg4});
  height: 240px;
  width: 225px;
  padding-left: 40px;
  padding-right: 40px;
  color: ${getColor('lightText')};
`;

const NoBrakeText = styled.span`
  white-space: nowrap;
`;

export {
  ReviewsContainer,
  Heading,
  CardsContainer,
  ReviewsHeading,
  NoBrakeText,
  Card1,
  Card2,
  Card3,
  Card4,
};
