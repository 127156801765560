import {FC} from 'react';
import {
  Container,
  TextContainer,
  SliderContainer,
  FeaturesHeading,
  TextBox,
  Text,
} from './FactsAndMyths.styled';

import {useNavigate} from 'react-router-dom';
import {SliderCard} from './components/SliderCard/SliderCard';
import {Slider} from 'modules/Slider';
import {useTheme} from 'styled-components';

const slidesData = [
  {
    myth: {
      text: 'Psychiatrists only prescribe medications and are quick to overmedicate.',
    },
    fact: {
      text: 'Not true. Our psychiatrists recommend medication only when necessary—after exploring other options or when benefits outweigh risks. They offer psychotherapy, investigate underlying conditions, and can suggest cutting-edge treatments like TMS and Ketamine-assisted therapy.',
    },
  },
  {
    myth: {
      text: 'Psychiatrists don’t care about your emotions; they only treat symptoms.',
    },
    fact: {
      text: "Our psychiatrists are dedicated to treating the whole person. They genuinely care about your emotional well-being. Unfortunately, corporate influences and misconceptions have tarnished the profession's image.",
    },
  },
  {
    myth: {text: 'Psychiatrists only focus on diagnoses and neglect holistic care.'},
    fact: {
      text: 'This is false. Our psychiatrists prioritize a comprehensive approach, seeing you as an individual rather than just a diagnosis. They explore your feelings, fears, and goals to create a tailored treatment plan that truly fits you.Experience the real essence of psychiatry—where healing the mind and soul comes first.',
    },
  },
];

const FactsAndMyths: FC = () => {
  const slidesComponents = slidesData.map(data => {
    return {bgComponent: () => <SliderCard {...data} />};
  });

  const {isMobile} = useTheme();

  return (
    <Container>
      <TextContainer>
        <TextBox>
          <FeaturesHeading>Bringing Back Real Psychiatry</FeaturesHeading>
          <Text>
            Psychiatry, derived from the ancient Greek words for "mind" (psyche) and
            "healing" (iatreia), literally means "medical treatment of the soul."
            Psychiatrists in this network embody this profound ethos, emphasizing holistic
            care and genuine connection. Let’s bust some common myths surrounding the
            profession:
          </Text>
        </TextBox>
      </TextContainer>
      <SliderContainer>
        <Slider
          data={slidesComponents}
          visibleSlides={1}
          spaceBetween={100}
          isVertical={!isMobile}
        />
      </SliderContainer>
    </Container>
  );
};

export {FactsAndMyths};
